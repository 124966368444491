import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PubroSpinner from '../common/PubroSpinner';
import ArticleList from "../publication/article/ArticleList";
import { sortArticles } from '../publication/article/ArticleSortUtil';
import IssueBasicInfo from "./IssueBasicInfo";
import { withTranslation } from 'react-i18next';
import { formatIssueLabel, isInPreviewMode } from '../common/utils';
import IssueCover from '../issue/IssueCover';
import Breadcrumbs from '../common/Breadcrumbs';
import { Helmet } from 'react-helmet-async'
import { parse } from 'query-string';
import { buildIssueJsonLd } from './IssueJsonLd';
import { MAIN_CONTENT_ID } from '../main-structure/SkipToContentLinks';
import SearchInJournalForm from '../search/form/SearchInJournalForm';
import { MatomoContext } from '@datapunt/matomo-tracker-react';
import { generateMetaDescription, generateMetaTitle, generateSeparatedList } from '../common/meta';
import DraftPreviewModeWarning from '../publication/common/DraftPreviewModeWarning';
import WbnAccessIcon from "../common/WbnAccessIcon";

/**
 * Renders journal issue details and a list of articles assigned to this issue.
 * Will fetch the issue from API when mounted.
 */
class IssueDetails extends React.Component {

  static contextType = MatomoContext;

  // -------------------- CONSTRUCTOR --------------------

  constructor(props) {
    super(props);
    this.state = {
      issue: null,
      articles: null,
      isLoading: true
    }
  }

  // -------------------- LIFECYCLE --------------------

  componentDidMount() {
    const { issueId } = this.props.match.params;
    const { previewToken } = parse(this.props.location.search);
    const config = previewToken ? { params: { previewToken } } : undefined;

    this.props.api.get(`/issues/${issueId}`, config)
      .then(response => this.setState({
        issue: response.data,
      }));
    this.props.api.get(`/issues/${issueId}/articles`, config)
      .then(response => this.setState({
        articles: sortArticles(response.data),
      }));
  }

  static getDerivedStateFromProps(props, state) {
    return {isLoading: state.issue === null || state.articles === null };
  }

  // -------------------- LOGIC --------------------

  render() {
    const t = this.props.t;
    if (this.state.isLoading) {
      return <PubroSpinner />
    }

    const jsonLd = buildIssueJsonLd(this.state.issue, this.state.articles);
    if (this.context && this.context.trackPageView) this.context.trackPageView({ documentTitle: formatIssueLabel(this.state.issue)});
    return (
      <>
        <Helmet title={this.getMetaTitle(t, this.state.issue)}
                meta={[{name: "description", content: this.getMetaDescription(t)}]}
                script={[{type: "application/ld+json", innerHTML: JSON.stringify(jsonLd)}]}/>
        <Breadcrumbs items={this.getBreadcrumbs(this.state.issue, t)} maxItemLength={75}/>
        <main id={MAIN_CONTENT_ID}>
          {this.basicDetails(t)}
          <Container className="issue-articles-container">
            <Row><h2>{t('issue.details.header.articles')}</h2></Row>
            <Row>
              <ArticleList articles={this.state.articles}/>
            </Row>
          </Container>
        </main>
      </>
    );
  }

  basicDetails(t) {
    if (this.state.issue) {
      return (
        <div className="tabbed-details-name-container">
          <div className="tabbed-details-name-container tabbed-details-sidebar-present">
            {isInPreviewMode(this) && <DraftPreviewModeWarning/>}
            <div className="tabbed-details-name-logo-container">
              <IssueCover coverId={this.state.issue.coverId} issueLabel={formatIssueLabel(this.state.issue)}/>
            </div>
            <div className="tabbed-details-name-header-container">
              <div className="tabbed-details-name-header">{t('issue.details.header.issue')}</div>
              {this.state.issue.restricted && <WbnAccessIcon type={'ISSUE'}/>}
              <h1 className="issue-name" data-issue="label">
                {formatIssueLabel(this.state.issue)}
              </h1>
              <IssueBasicInfo issue={this.state.issue}/>
            </div>
          </div>
          <div className="tabbed-details-sidebar">
            <SearchInJournalForm journalId={this.state.issue.journalInfo.id} />
          </div>
        </div>
      )
    } else {
      return <PubroSpinner/>
    }
  }

  getBreadcrumbs(issue, t) {
    return [
      { label: issue.journalInfo.publishingCompanyInfo.name, link: `/publishing-companies/${issue.journalInfo.publishingCompanyInfo.id}`},
      { label: issue.journalInfo.title, link: `/journals/${issue.journalInfo.id}`},
      { label: formatIssueLabel(issue), link: null},
    ];
  }

  getMetaTitle(t, issue) {
    return generateMetaTitle(t, {"text": issue.journalInfo.title, "subText": formatIssueLabel(issue)});
  }

  getMetaDescription(t) {
    const defaultDescription = generateMetaDescription(t, {});
    const articles = this.state.articles;

    if (articles) {
      let description = "";

      description = generateSeparatedList({"type": "articles", "data": articles})

      if (description.length === 0) {
        return defaultDescription;
      }
      else {
        return generateMetaDescription(t, {"text": description})
      }
    }
    else {
      return defaultDescription;
    }
  }
}

export default withTranslation()(IssueDetails);
